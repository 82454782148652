import { cleanParam } from "src/helpers/router"
import { buildFullResourceServices } from "src/helpers/services"
import { treeMode } from "../../src/constants/tree"
import authValidationMiddleware from "./authValidationMiddleware"
import axiosApiInstance from "./httpInterceptor"

export const getPostponedAudits = authValidationMiddleware(async (params) => {
  const response = await axiosApiInstance.get(`/proyecto/riesgo/auditoria/aplazamientos/`, { params })

  return response.data
})

export const getAuditsByYearAndTab = authValidationMiddleware(
  async ({ year, copy_type, unidad_negocio, search = null, start = null, end = null }) => {
    const response = await axiosApiInstance.get(
      `/proyecto/riesgo/auditoria/?ano_auditoria=${year}&copy_type=${copy_type}&max_per_page=10000&unidad_negocio=${unidad_negocio}${
        search ? `&search=${search}` : ""
      }${!!start ? `&fecha_inicio=${start}` : ""}${!!end ? `&fecha_fin=${end}` : ""}`
    )

    return response.data
  }
)

export const revertPostponedAudit = authValidationMiddleware(async (from) => {
  const response = await axiosApiInstance.post(
    `/proyecto/riesgo/auditoria/aplazamiento_revert/`,
    {},
    { params: { from } }
  )
  return response.data
})

export const getAuditsByYear = authValidationMiddleware(
  async ({ year, entriesPerPage = null, page = 1, search = null, start = null, end = null, ...otherParams }) => {
    const params = { page, ...otherParams }
    if (year) {
      params.ano_auditoria = year
    }

    if (entriesPerPage !== null) {
      params.max_per_page = entriesPerPage
    }
    if (search) {
      params.search = search
    }

    if (start) {
      params.fecha_inicio = start
    }

    if (end) {
      params.fecha_fin = end
    }

    const response = await axiosApiInstance.get("/proyecto/riesgo/auditoria/", { params })

    return response.data
  }
)

export const getAudit = authValidationMiddleware(async (id, params = {}) => {
  const response = await axiosApiInstance.get(`/proyecto/riesgo/auditoria/${id}/`, { params })

  return response.data
})

export const deleteAudit = authValidationMiddleware(async (id) => {
  const response = await axiosApiInstance.delete(`/proyecto/riesgo/auditoria/${id}/`)

  return response.data
})

export const createAudit = authValidationMiddleware(async (obj) => {
  const response = await axiosApiInstance.post(`/proyecto/riesgo/auditoria/`, {
    ...obj
  })

  return response.data
})

export const updateAudit = authValidationMiddleware(async ({ auditId, auditBody, typeTree = treeMode.AUDITPLAN }) => {
  const response = await axiosApiInstance.put(
    `/proyecto/riesgo/${treeMode.AUDITPLAN === typeTree ? "auditoria" : "evaluacion"}/${auditId}/`,
    {
      ...auditBody
    }
  )

  return response.data
})

export const updateFrequencyAudit = authValidationMiddleware(async (auditId, tipo_frecuencia, params = {}) => {
  const response = await axiosApiInstance.put(`/proyecto/riesgo/auditoria/${auditId}/change_frequency/`, {
    tipo_frecuencia,
    ...params
  })

  return response.data
})

export const getAuditMyPermissions = authValidationMiddleware(async (auditId) => {
  const response = await axiosApiInstance.get(`/proyecto/riesgo/auditoria/${auditId}/permissions_me/`)

  return response.data
})

export const getAuditWithPermissions = async (auditId) => {
  const [{ data: auditData }, { data: auditPermissions }] = await Promise.all([
    getAudit(auditId),
    getAuditMyPermissions(auditId)
  ])

  return {
    ...auditData,
    permissions: auditPermissions.permissions,
    permissions_extra: { role_type: auditPermissions.role_type, in_team: auditPermissions.in_team }
  }
}

export const getDashboardByAudit = authValidationMiddleware(async (auditId, stats, extraParams = {}) => {
  const params = { ...extraParams, stats }
  const response = await axiosApiInstance.get(`/proyecto/riesgo/auditoria/${auditId}/dashboard_data/`, { params })

  return response.data
})

export const getDashboardDataByYear = authValidationMiddleware(
  async (year, empresa = null, un = null, user = null, filters = {}) => {
    const params = {
      ano_auditoria: year,
      ...filters
    }
    if (cleanParam(empresa)) {
      params.empresa = empresa
    }
    if (cleanParam(un)) {
      params.unidad_negocio = un
    }
    if (cleanParam(user)) {
      params.usuario = user
    }

    const response = await axiosApiInstance.get(`/proyecto/riesgo/auditoria/global_dashboard_data/`, { params })
    return response.data.data
  }
)

export const getFollowUpActionPlan = authValidationMiddleware(async ({ id }, params) => {
  const response = await axiosApiInstance.get(`/proyecto/riesgo/tree_item/auditoria/${id}/hallazgos/`, {
    params
  })

  return response.data
})

export const getItemsAudit = authValidationMiddleware(async ({ auditId, audit_type = "auditoria", parentId }) => {
  const params = parentId !== "" ? { parent: parentId } : ""

  const response = await axiosApiInstance.get(`/proyecto/riesgo/${audit_type}/${auditId}/items/`, { params })

  return response.data
})

export const getAuditGenericService = authValidationMiddleware(
  async ({ service, id, typeTree = "auditoria", params = {} }) => {
    const response = await axiosApiInstance.get(`/proyecto/riesgo/${typeTree}/${id}/${service}/`, { params })

    return response.data
  }
)

export const postAuditGenericService = authValidationMiddleware(async ({ service, id, payload, params = {} }) => {
  const response = await axiosApiInstance.post(`/proyecto/riesgo/auditoria/${id}/${service}/`, payload, { params })

  return response.data
})

export const getAuditListGenericService = authValidationMiddleware(async (service, params = {}) => {
  const response = await axiosApiInstance.get(`/proyecto/riesgo/auditoria/${service}/`, { params })

  return response.data
})

export const getAuditConfigs = authValidationMiddleware(async (auditId) => {
  const response = await axiosApiInstance.get(`/proyecto/riesgo/auditoria/${auditId}/configs/`)

  return response.data
})

export const setAuditConfigs = authValidationMiddleware(async (auditId, values) => {
  const response = await axiosApiInstance.post(`/proyecto/riesgo/auditoria/${auditId}/configs/`, values)

  return response.data
})

export const requestBackup = (auditId) => {
  const URL = `/proyecto/riesgo/auditoria/${auditId}/request_backup/`

  return {
    URL,
    GET: authValidationMiddleware(async () => {
      const response = await axiosApiInstance.get(URL)
      return response.data
    }),
    POST: authValidationMiddleware(async () => {
      const response = await axiosApiInstance.post(URL)
      return response.data
    }),
    DOWNLOAD: () => {
      const token = localStorage.getItem("token")

      return `${process.env.apiUrl}/proyecto/riesgo/auditoria/${auditId}/download_backup/?token=${token}`
    }
  }
}

export const auditTemplatesServices = buildFullResourceServices("/proyecto/riesgo/auditoria_template/")
