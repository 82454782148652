import authValidationMiddleware from "src/services/authValidationMiddleware"
import axiosApiInstance from "src/services/httpInterceptor"

export const buildFullResourceServices = (url, additionalServices = {}) => {
  return (url_params) => {
    const URL = typeof url === "function" ? url(url_params) : url

    return {
      URL,
      LIST: authValidationMiddleware(async (params = {}) => {
        const response = await axiosApiInstance.get(URL, { params })
        return response.data
      }),
      RETRIEVE: authValidationMiddleware(async (id, params = {}) => {
        const response = await axiosApiInstance.get(URL + id + "/", params)
        return response.data
      }),
      CREATE: authValidationMiddleware(async (payload) => {
        const response = await axiosApiInstance.post(URL, payload)
        return response.data
      }),
      UPDATE: authValidationMiddleware(async (id, payload) => {
        const response = await axiosApiInstance.put(URL + id + "/", payload)
        return response.data
      }),
      DELETE: authValidationMiddleware(async (id) => {
        const response = await axiosApiInstance.delete(URL + id + "/")
        return response.data
      }),
      LIST_ACTION: authValidationMiddleware(async (service, params = {}) => {
        const response = await axiosApiInstance.get(URL + service + "/", { params })
        return response.data
      }),
      POST_ACTION: authValidationMiddleware(async (service, payload, params = {}) => {
        const response = await axiosApiInstance.post(URL + service + "/", payload, { params })
        return response.data
      }),
      GET_OBJECT_ACTION: authValidationMiddleware(async (service, id, params = {}) => {
        const response = await axiosApiInstance.get(URL + id + "/" + service + "/", { params })
        return response.data
      }),
      POST_OBJECT_ACTION: authValidationMiddleware(
        async (service, id, payload, params = {}, header = {}, configs = {}) => {
          const response = await axiosApiInstance.post(URL + id + "/" + service + "/", payload, {
            params,
            header,
            ...configs
          })
          return response.data
        }
      ),
      DELETE_OBJECT_ACTION: authValidationMiddleware(async (service, id, params = {}, header = {}, configs = {}) => {
        const response = await axiosApiInstance.delete(URL + id + "/" + service + "/", {
          params,
          header,
          ...configs
        })
        return response.data
      }),
      ...additionalServices
    }
  }
}
